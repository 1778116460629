import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Row, Col, Button } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import Lightbox from 'react-image-lightbox';

function ImageGallery() {
  const [imageObjects, setImageObjects] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const itemsCountPerPage = 12;

  useEffect(() => {
    const fetchData = async () => {
      const db = firebase.database();
      const snapshot = await db.ref('images').get();
      const imageData = snapshot.val();

      const storage = firebase.storage();
      const currentUser = firebase.auth().currentUser;
      const imageObjects = await Promise.all(
        Object.keys(imageData)
          .filter(key => imageData[key].uploaderUID === currentUser.uid)
          .map(async (key) => {
            const url = await storage.ref(imageData[key].storageFilePath).getDownloadURL();
            return { ...imageData[key], url, id: key };
          })
      );

      // Sort images by uploadDateTime in descending order
      imageObjects.sort((a, b) => b.uploadDateTime - a.uploadDateTime);

      setImageObjects(imageObjects);
    };

    fetchData();
  }, []);

  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setActivePage(pageNumber);
  }

  const handleDelete = async (image) => {
    const confirmation = window.confirm('Are you sure you want to delete this image?');
    if (confirmation) {
      const storage = firebase.storage();
      // Delete from storage
      await storage.ref(image.storageFilePath).delete();

      const db = firebase.database();
      // Delete from database
      await db.ref(`images/${image.id}`).remove();

      alert('Image deleted successfully.');

      // Remove image from local state
      setImageObjects(imageObjects.filter(img => img.id !== image.id));
    }
  };

  return (
    <>
        <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={imageObjects.length}
        pageRangeDisplayed={7}
        onChange={handlePageChange}
        itemClass="page-item"
        linkClass="page-link"
      />

      {imageObjects.length > 0 ? (
      <Row>
        {imageObjects.slice((activePage - 1) * itemsCountPerPage, activePage * itemsCountPerPage).map((image, index) => (
          <Col xs={6} md={4} lg={3} key={index} onClick={() => {
            setIsOpen(true);
            setPhotoIndex(index);
          }}>
            <Card className="mb-4 shadow-sm bg-cultured txt-vampire gallery-image-card">
              <Card.Title className="text-center">Score: {image.score}</Card.Title>
              <Card.Img variant="top" src={image.url} className="polaroid" alt={image.amFilename} title={image.amFilename} />
              <Card.Body>
                <Card.Text className="text-center">
                  {/*<p>Uploader ID:{image.uploaderUID}</p>
                  <p><b>Score</b>: {image.score}</p>
                  <p>Upload Time: {new Date(image.uploadDateTime).toLocaleString()}</p> */}
                  
                  <p><b>RefID</b>:<br />{image.amFilename}</p>
                </Card.Text>
                <center><Button variant="danger" onClick={() => handleDelete(image)}>
                  Delete
                </Button></center>
              </Card.Body>
            </Card>
          </Col>
        ))}

        {isOpen && (
          <Lightbox
            mainSrc={imageObjects[photoIndex].url}
            nextSrc={imageObjects[(photoIndex + 1) % imageObjects.length].url}
            prevSrc={imageObjects[(photoIndex + imageObjects.length - 1) % imageObjects.length].url}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + imageObjects.length - 1) % imageObjects.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imageObjects.length)
            }
          />
        )}
      </Row>
      ) : (
        <div className="text-center mt-5">
          <h4 className="txt-crimson">Nothing found. Please upload your content.</h4>
        </div>
      )}

      
    </>
  );
}

export default ImageGallery;
