
import React from 'react'
import {Navbar, Nav, Image} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { fadCompass } from '@fortawesome/fontawesome-pro'
import { faHome, faCompass, faCamera, faUpload, faInfoCircle, faTag, faUser, faSignInAlt, faLayerPlus, faImages } from '@fortawesome/free-solid-svg-icons'
// import { fadCompass, fadCamera, fadUpload, fadInfoCircle, fadTag, fadUser, fadSignInAlt } from '@fortawesome/free-solid-svg-icons'

import { useAuth } from "../contexts/FirebaseAuth"

import "./TopNav.css"

export default function TopNav() {
    const { currentUser } = useAuth()
    //const [expanded, setExpanded] = useState(false)

    return (
        <>
        <Navbar bg="vampire" // controls nav background color
            className="text-cultured header-nav" // Custom Styling
            fixed="top" // keeps nav at top (not necessary when sticky top is set)
            sticky="top" // pushes page below, prevents overlap when expanded
            expand="sm" // collapse when less wide than this breakpoint
            collapseOnSelect // collapse after a nav link has been clicked
            style={{marginBottom:"5px"}}

            // OVERRIDING DEFAULT BEHAVIOR SO WE CAN DETECT CURRENT TOGGLED STATE
            //expanded={expanded}
            //onToggle={next => setExpanded(next)}

        >
            <Navbar.Brand href="/" style={{fontSize:22}} className="mr-5">
                <img src="./assets/artmash_logo_color.png" style={{maxHeight: '35px'}} alt="ArtMash Logo" title="ArtMash | Pairing Art to Create New Designs" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-charleston" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    
                    {currentUser &&
                        <Nav.Link href="/explore" className="nav-text" style={{color: '#E6E6E6'}}>Explore</Nav.Link>
                    }
                    {currentUser &&
                        <Nav.Link href="/camera" className="nav-text" style={{color: '#E6E6E6'}}>Camera</Nav.Link>
                    }
                    {currentUser &&
                        <Nav.Link href="/share" className="nav-text" style={{color: '#E6E6E6'}}>Share</Nav.Link>
                    }
                    {currentUser &&
                        <Nav.Link href="/uploads" className="nav-text" style={{color: '#E6E6E6'}}>Uploads</Nav.Link>
                    }
                    <Nav.Link href="/privacy" className="nav-text" style={{color: '#E6E6E6'}}>Privacy</Nav.Link>
                    {/* currentUser &&
                        <Nav.Link href="/products" style={{color: '#E6E6E6'}}>Products</Nav.Link>
                        <Nav.Link href="/about" className="nav-text" style={{color: '#E6E6E6'}}>About</Nav.Link>
                    */}
                    {!currentUser &&
                        <Nav.Link href="/login" className="nav-text" style={{color: '#E6E6E6'}}>Login</Nav.Link>
                    }
                </Nav>

               { currentUser &&
                    <Nav.Link href="/profile" className="profile-link">
                        <Image roundedCircle src={currentUser.photoURL} alt="user profile" height="35px"/>
                    </Nav.Link>
                }

            </Navbar.Collapse>
        </Navbar>
        
        <Navbar bg="vampire"
            className="text-cultured mobile-nav pb-3"
            fixed="bottom"
            expand="sm"
            style={{marginTop:"10px"}}
    >
        <Nav className="m-auto justify-content-around flex-row">
  
            <Nav.Link href="/explore" className="mobile-nav-button">
                <FontAwesomeIcon icon={faCompass} style={{color: '#E6E6E6'}} />
            </Nav.Link>
            <Nav.Link href="/camera" className="mobile-nav-button">
                <FontAwesomeIcon icon={faCamera} style={{color: '#E6E6E6'}} />
            </Nav.Link>
            <Nav.Link href="/share" className="mobile-nav-button">
                <FontAwesomeIcon icon={faUpload} style={{color: '#E6E6E6'}} />
            </Nav.Link>
            <Nav.Link href="/uploads" className="mobile-nav-button">
                <FontAwesomeIcon icon={faImages} style={{color: '#E6E6E6'}} />
            </Nav.Link>
            {!currentUser &&
                <Nav.Link href="/login" className="mobile-nav-button">
                    <FontAwesomeIcon icon={faSignInAlt} style={{color: '#E6E6E6'}} />
                </Nav.Link>
            }
            {currentUser &&
                <Nav.Link href="/profile" className="profile-link">
                    <Image roundedCircle src={currentUser.photoURL} alt="user profile" height="35px"/>
                </Nav.Link>
            }
        </Nav>
    </Navbar>
        </>
    )
}
