

import React from 'react'
import ReactGA from 'react-ga'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'

import { AuthProvider } from "../contexts/FirebaseAuth"
import { FlashProvider } from "../contexts/FlashContext"
import ProtectedRoute from "./ProtectedRoute"

import FlashContainer from "./FlashContainer"
import TopNav from "./TopNav"
//import Footer from "./Footer"

//import Home from "./Home"
import Products from "./Products"
//import About from "./AMAbout"
import Login from "./GoogleLogin"
//import AltLogin from "./Login"
//import UserProfile from "./Profile"
import AlertMe from "./AlertMe" // example page for seeing different alert styling options
import FlashForm from "./FlashForm" // example page for testing and debugging the flash

// Custom Changes
import Home from "./AMHome2"
//import Home2 from "./AMHome2"
import Share from "./AMShare2"
import Uploads from "./AMUploads2"
import Gallery from "./AMGallery"
import Explore from "./AMExplore4"
//import ExploreBeta2 from "./AMExplore4"
import Camera from "./AMCamera"
import UserProfile from "./AMProfile"
import IDDQD from "./AMGodMode"
import Privacy from "./AMPrivacy"
import RandomMash from "./AMRandomMash"

import 'bootstrap/dist/css/bootstrap.min.css'
import './AMexplore.css';
import './AMStyles.css';

const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID
const GA_DEBUG_MODE = (process.env.REACT_APP_GA_DEBUG_MODE === "true")
ReactGA.initialize(GA_TRACKER_ID, {debug: GA_DEBUG_MODE})

export default function App() {
    ReactGA.pageview(window.location.href)

    return (
        <AuthProvider>
        <FlashProvider>
            <div className="App">
                <TopNav/>

                <Container className="mb-5">
                {/* <div className="containerFluid px-2"> */}
                    <FlashContainer/>

                    <Router>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/privacy" component={Privacy} />
                            <ProtectedRoute exact path="/products" component={Products} />
                            <Route exact path="/explore" component={Explore} />
                            <ProtectedRoute exact path="/camera" component={Camera} />
                            <ProtectedRoute exact path="/share" component={Share} />
                            <ProtectedRoute exact path="/uploads" component={Uploads} />
                            <Route exact path="/login" component={Login} />
                            <ProtectedRoute exact path="/profile" component={UserProfile} />

                            <ProtectedRoute exact path="/iddqd" component={IDDQD} />

                            <Route path="/alerts" component={AlertMe} />
                            <Route path="/flashes" component={FlashForm} />
                        </Switch>
                    </Router>
                {/* </div> */}
                </Container>

                {/* <Footer/> */}
            </div>
        </FlashProvider>
        </AuthProvider>
    )
}
