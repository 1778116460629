import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/database';
import { useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FaTrashAlt } from 'react-icons/fa';
import './AMUploads.css'
import { useFlashUpdate } from "../contexts/FlashContext"

const ImageGallery = () => {
  const [imagesWebp, setImagesWebp] = useState([]);
  const [photoIndexWebp, setPhotoIndexWebp] = useState(0);
  const [isOpenWebp, setIsOpenWebp] = useState(false);
  const [maxImages, setMaxImages] = useState(3); // Set the maximum number of images to display

  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = firebase.storage().ref();
      const uid = firebase.auth().currentUser.uid;

      const imagesWebpRef = storageRef.child(`masks/${uid}`);

      const [imagesWebpFiles] = await Promise.all([imagesWebpRef.listAll()]);

      // Fetch from Firebase Realtime Database to get dbKey
      const dbRef = firebase.database().ref('images');
      const snapshot = await dbRef.once('value');
      const data = snapshot.val();

      const imagesWebpUrls = await Promise.all(
        imagesWebpFiles.items.map(async (file) => {
          const { fullPath } = file;
          const dbKey = data[fullPath]?.dbKey;

          return {
            url: await file.getDownloadURL(),
            path: fullPath,
            dbKey
          };
        })
      );

      setImagesWebp(imagesWebpUrls.slice(0, maxImages)); // Limit the number of images to display
    };

    fetchImages();
  }, [maxImages]); // Update images when maxImages changes

  const handleDelete = async (image) => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(image.path);

      await fileRef.delete();

      const dbRef = firebase.database().ref('images').child(image.dbKey);
      await dbRef.remove();

      setImagesWebp(imagesWebp.filter((img) => img.path !== image.path));
      alert('This image has been deleted.');
    }
  };

  return (
    <>
      <Row className="mx-0">
        {imagesWebp.length > 0 ? (
          imagesWebp.map((img, index) => (
            <Col xs={4} md={4} lg={4} key={img.url} className="mx-0 px-1" >
              <img
                src={img.url}
                className="top3-tile"
                alt={`img${index}`}
                onClick={() => {
                  setPhotoIndexWebp(index);
                  setIsOpenWebp(true);
                }}
                style={{ width: '100%', cursor: 'pointer' , marginBottom: '0.5em'}}
              />
              {/* <FaTrashAlt
                onClick={() => handleDelete(img)}
                style={{ position: 'absolute', cursor: 'pointer', color: 'red', bottom: '10px', left: '10px' }}
              /> */}
            </Col>
          ))
        ) : (
          <div>
            Nothing here yet, please <a href="/share">Share</a> your content first.
          </div>
        )}
      </Row>

      {isOpenWebp && (
        <Lightbox
          mainSrc={imagesWebp[photoIndexWebp].url}
          nextSrc={imagesWebp[(photoIndexWebp + 1) % imagesWebp.length].url}
          prevSrc={imagesWebp[(photoIndexWebp + imagesWebp.length - 1) % imagesWebp.length].url}
          onCloseRequest={() => setIsOpenWebp(false)}
          onMovePrevRequest={() =>
            setPhotoIndexWebp((photoIndexWebp + imagesWebp.length - 1) % imagesWebp.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndexWebp((photoIndexWebp + 1) % imagesWebp.length)
          }
          imageCaption="Click or tap to close"
        />
      )}
    </>
  );
};

export default ImageGallery;
