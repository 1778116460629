

// import React, {PureComponent} from 'react';
import React, { useEffect, useState, PureComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Card, Button, Image, Tab, Nav, Row, Col, Table, Form, Alert } from 'react-bootstrap';

import app, {fetchOrders} from "../firebase";
import { useAuth } from "../contexts/FirebaseAuth";
import { useFlashUpdate } from "../contexts/FlashContext";
import InputMask from 'react-input-mask';

import AMJustImages from './AMJustImages';
import AMJustMasks from './AMJustMasks';
import AMGalleryImagesUID from './AMGalleryImagesUID.js';
import AMGalleryMasksUID from './AMGalleryMasksUID.js';
import AMFavoriteMash from './AMFavoriteMash.js';
import AMSupporters from './AMSupporters.js';

function AccountTab(props){
    const user = props.user
    const flash = useFlashUpdate()
    const history = useHistory()
    const [amUserName, setAmUserName] = useState('');
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const userRef = app.database().ref(`/users/${user.uid}/amUserName`);
        userRef.on('value', (snapshot) => {
          const data = snapshot.val();
          setAmUserName(data || '');
        });
        return () => {
          userRef.off();
        };
      }, [user.uid]);

    async function handleLogout() {
        await app.auth().signOut()
        console.log("LOGOUT SUCCESS")
        flash({message:"Logout success. Have a nice day!", variant: "success"})
        history.push("/login")
    }

    async function handleSubmit(e) {
    e.preventDefault();
    setError('');
    try {
      await app.database().ref(`/users/${user.uid}`).update({ amUserName });
      setEditing(false);
      flash({ message: 'Username updated successfully!', variant: 'success' });
    } catch {
      setError('Failed to update the username');
    }
  }

    return (
        <>
            <h2>Account Info</h2>
            <p className="lead">You are logged in as...</p>

            {/* <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100 hide-me" style={{ maxWidth: "400px"}}>
                    <Card className="bg-cultured txt-charleston">
                        <Card.Body>
                            <Image roundedCircle
                                    src={user.photoURL}
                                    alt="user profile"
                                    style={{
                                        marginBottom:15,
                                        display:"block",
                                        marginLeft:"auto",
                                        marginRight:"auto",

                                    }}
                                    //height="65px"
                                />

                            
                            <div>ArtMash Username <span className="txt-good txt-bold">(Public)</span>: 
                                {editing ? (
                                    <Form onSubmit={handleSubmit}>
                                    <InputMask
                                    mask="*******************"
                                    maskChar={null}
                                    value={amUserName}
                                    onChange={(e) => setAmUserName(e.target.value.replace(/[^a-z0-9]/gi,''))}
                                    />
                                    <Button type="submit">Save</Button>
                                </Form>
                                ) : (
                                    <pre onClick={() => setEditing(true)}>{amUserName || 'Click to set username'}</pre>
                                )}
                                </div>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <div>Your Name <span className="txt-crimson txt-bold">(Private)</span>: <pre>{user.displayName}</pre></div>
                                <div>Your  Email <span className="txt-crimson txt-bold">(Private)</span>: <pre>{user.email}</pre></div>
                                <div>Your User Id <span className="txt-crimson txt-bold">(Private)</span>: <pre>{user.uid}</pre></div>
                        </Card.Body>
                    </Card>

                    <Button variant="link" onClick={handleLogout}>
                        Log Out
                    </Button>
                </div>
            </Container> */}
            <div className="container align-items-center justify-content-center">
                <div className="row align-items-center justify-content-center">
                    <div className="col-sm-12 col-lg-6 align-items-center justify-content-center">
                        <Card className="bg-cultured txt-charleston">
                        <Card.Body>
                            <Image roundedCircle
                                    src={user.photoURL}
                                    alt="user profile"
                                    style={{
                                        marginBottom:15,
                                        display:"block",
                                        marginLeft:"auto",
                                        marginRight:"auto",

                                    }}
                                    //height="65px"
                                />

                            
                            <div className="profile-text">ArtMash Username <span className="txt-good txt-bold">(Public)</span>: 
                                {editing ? (
                                    <Form onSubmit={handleSubmit}>
                                    <InputMask
                                    mask="*******************"
                                    maskChar={null}
                                    value={amUserName}
                                    onChange={(e) => setAmUserName(e.target.value.replace(/[^a-z0-9]/gi,''))}
                                    />
                                    <Button type="submit">Save</Button>
                                </Form>
                                ) : (
                                    <pre onClick={() => setEditing(true)}>{amUserName || 'Click to set username'}</pre>
                                )}
                                </div>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <div className="profile-text">Your Name <span className="txt-crimson txt-bold">(Private)</span>: <pre>{user.displayName}</pre></div>
                                <div className="profile-text">Your  Email <span className="txt-crimson txt-bold">(Private)</span>: <pre>{user.email}</pre></div>
                                <div className="profile-text">Your User Id <span className="txt-crimson txt-bold">(Private)</span>: <pre>{user.uid}</pre></div>
                        </Card.Body>
                    </Card>
                    <center>
                    <Button variant="danger" className="mt-5 mb-3" onClick={handleLogout}>
                        Log Out
                    </Button>
                    </center>
                    </div>
                    <div className="col-sm-12 col-lg-6 align-items-center justify-content-center text-center mb-3">
                        <h3>How can you support ArtMash?</h3>    

                        <form action="https://www.paypal.com/donate" method="post" target="_top">
                        <input type="hidden" name="business" value="LSMNH497QJVWG" />
                        <input type="hidden" name="no_recurring" value="1" />
                        <input type="hidden" name="item_name" value="Donations received will be put towards hosting and other costs associated with creating, running, and improving ArtMash." />
                        <input type="hidden" name="currency_code" value="USD" />
                        <input type="image" src="https://pics.paypal.com/00/s/ODU0NGU3NzQtNDQxMC00MDVkLWE0ODAtMWMxMTcxYTQ5NGRm/file.PNG" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>

                        <p className="px-2 pb-3">Click the image above to support ArtMash via PayPal donation.<br /><br />ArtMash is a personal project with a goal of bringing communities of creators together. This venture comes with various costs and we appreciate any support you would like to provide.<br /><br />If desired, a unique identifier (name, email, username, etc..) can be added to the Supporters tab on the User Profile section of the website. This will also include the amount of your contribution.<br /><br />As more features are added, we will notify and engage with donators to get feedback before releasing to the public.</p>


                    </div>
                </div>
            </div>
        </>
    )
}

class OrdersTab extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {orders: []}
    }

    render(){
        var rows = this.state.orders.map((order) => {
            var orderDate = new Date(parseInt(order.orderAt))
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
            return (
                <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{order.productName}</td>
                    <td>{order.productPrice}</td>
                    <td>{orderDate.toLocaleString()}</td>
                </tr>
            )
        })

        return (
            <>
                <h2>Order History</h2>
                <p className="lead">Here are your recent orders...</p>

                <Table striped bordered hover responsive className="txt-cultured">
                    <thead>
                        <tr>
                            <th>Order Id</th>
                            <th>Product Name</th>
                            <th>Product Price</th>
                            <th>Order Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>

            </>
        )
    }

    async componentDidMount(){
        console.log("ORDERS TAB DID MOUNT")
        var orders = await fetchOrders(this.props.user)
        //console.log("MOUNTED WITH", products.length, "PRODUCTS")
        var sortedOrders = orders.sort((a,b) => parseInt(b.orderAt) - parseInt(a.orderAt)) // DESC
        this.setState({orders: sortedOrders})
    }
}

function ImagesTab(props){
    const user = props.user

    const flash = useFlashUpdate()
    const history = useHistory()

    return (
        <AMGalleryImagesUID />
    )
}

function MasksTab(props){
    const user = props.user

    const flash = useFlashUpdate()
    const history = useHistory()

    return (
        <AMGalleryMasksUID />
    )
}

function FavoritesTab(props){
    const user = props.user

    const flash = useFlashUpdate()
    const history = useHistory()

    return (
        <AMFavoriteMash />
    )
}

function SupporterTab(props){
    const user = props.user

    const flash = useFlashUpdate()
    const history = useHistory()

    return (
        <AMSupporters />
    )
}

export default function Profile() {
    const { currentUser } = useAuth()

    //console.log("WINDOW LOCATION", window.location)
    var activeTabKey = "account"
    if(window.location.hash === "#orders"){
        activeTabKey = "orders"
    }

    return (
        <>
            <h1>User Profile</h1>

            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTabKey} transition={false}>
                <Nav variant="tabs" style={{marginBottom:"0em"}}>
                    <Nav.Item>
                        <Nav.Link eventKey="account" className="px-3 bg-dark text-light">Account</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey="images" className="px-3 bg-dark text-light">Images</Nav.Link>
                    </Nav.Item>
        
                    <Nav.Item>
                        <Nav.Link eventKey="masks" className="px-3 bg-dark text-light">Masks</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey="saved" className="px-3 bg-dark text-light">Saved</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey="supporters" className="px-3 bg-dark text-light">Supporters</Nav.Link>
                    </Nav.Item>
        
                    {/* <Nav.Item>
                        <Nav.Link eventKey="orders" className="px-3 bg-dark text-light">Orders</Nav.Link>
                    </Nav.Item> */}
                </Nav>

                <Tab.Content className="bg-charleston txt-cultured px-2 py-2">
                    <Tab.Pane eventKey="orders">
                        <OrdersTab user={currentUser}/>
                    </Tab.Pane>
        
                    <Tab.Pane eventKey="images">
                        <ImagesTab user={currentUser}/>
                    </Tab.Pane>
        
                    <Tab.Pane eventKey="masks">
                        <MasksTab user={currentUser}/>
                    </Tab.Pane>

                    <Tab.Pane eventKey="saved">
                        <FavoritesTab user={currentUser}/>
                    </Tab.Pane>
        
                    <Tab.Pane eventKey="account">
                        <AccountTab user={currentUser}/>
                    </Tab.Pane>

                    <Tab.Pane eventKey="supporters">
                        <SupporterTab user={currentUser}/>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    )
}
