

import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/database';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import React from 'react'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { useFlashUpdate } from "../contexts/FlashContext"

const MultipleMaskUpload = () => {
  const flash = useFlashUpdate();
  const [files, setFiles] = useState([]);

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;

    // Convert the FileList object to an array
    const filesArray = Array.from(selectedFiles);

    // Filter and validate the selected files
    const validatedFiles = filesArray.filter((file) => {
      return (
        ['image/png', 'image/webp'].includes(file.type) &&
        file.size <= 10000000 // 10MB
      );
    });

    setFiles(validatedFiles);
  };

  // Handle upload
  const handleUpload = async () => {
    const storageRef = firebase.storage().ref();

    for (const file of files) {
      const randomName = generateRandomName();
      const fileExtension = file.name.split('.').pop();
      const newFileName = `${randomName}.${fileExtension}`;

      const fileRef = storageRef.child(`masks/${firebase.auth().currentUser.uid}/${newFileName}`);

      const dbRef = firebase.database().ref('masks');
      const newImageRef = dbRef.push();
      await newImageRef.set({
        filename: file.name,
        amFilename: randomName,
        uploaderUID: firebase.auth().currentUser.uid,
        storageFilePath: fileRef.fullPath,
        uploadDateTime: firebase.database.ServerValue.TIMESTAMP,
        score: 0.0, // initial score
      });

      const metadata = {
        customMetadata: {
          'dbKey': newImageRef.key,
        },
      };

      await fileRef.put(file, metadata);
    }

    flash({ message: 'Image Upload Successful!', variant: 'success' });
  };

    // Generate a random 12-character string of hexadecimal digits
    const generateRandomName = () => {
  const characters = '0123456789abcdef';
  let result = '';
  for (let i = 0; i < 12; i++) {
    result += characters[Math.floor(Math.random() * characters.length)];
  }
  return result;
};

  return (
    <Container>
      <Row>
        <Col>
          <Card className="bg-charleston text-center custom-card">
            <Card.Body>
              <Card.Title>Multiple Mask Upload</Card.Title>
              <Form>
                <Form.Group>
                  <Form.File
                    id="multiple-file-upload"
                    label="Select multiple masks"
                    multiple
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </Form>
              <Button variant="primary" onClick={handleUpload}>
                Upload
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};



export default MultipleMaskUpload;
