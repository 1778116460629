import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Row, Col } from 'react-bootstrap';
import AMCustomLightbox from './AMCustomLightbox'; // import your custom component
// import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

function FavoriteMash() {
  const [favoriteMashes, setFavoriteMashes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = firebase.auth().currentUser;
      const db = firebase.database();
      const storage = firebase.storage();
      const snapshot = await db.ref(`users/${currentUser.uid}/favorites`).get();
      const favoritesData = snapshot.val();

      if (!favoritesData) {
        // No favorites found
        return;
      }

      // Fetch images and masks associated with each favorite
      const favoriteMashes = await Promise.all(
        Object.values(favoritesData).map(async (favorite) => {
          // Fetch image
          const imageSnapshot = await db.ref(`images/${favorite.image}`).get();
          const imageUrl = await storage.ref(imageSnapshot.val().storageFilePath).getDownloadURL();

          // Fetch mask
          const maskSnapshot = await db.ref(`masks/${favorite.mask}`).get();
          const maskUrl = await storage.ref(maskSnapshot.val().storageFilePath).getDownloadURL();

          return { imageUrl, maskUrl };
        })
      );

      setFavoriteMashes(favoriteMashes);
    };

    fetchData();
  }, []);

  return (
    <>
      <Row className="text-center align-items-center justify-items-center">
        <Col>
        <p>We are working on bringing a custom LightBox to support viewing saved mashed in higher quality. Thank you for your patience.</p>
        </Col>
      </Row>
      <Row>
        {favoriteMashes.map(({ imageUrl, maskUrl }, index) => (
          <Col xs={6} sm={3} key={index}>
            <Card className="mb-4 shadow-sm bg-vampire txt-cultured py-2 gallery-image-card" onClick={() => {
              setIsOpen(true);
              setPhotoIndex(index);
            }}>
              <Card.Img variant="top" src={imageUrl} style={{ WebkitMaskImage: `url(${maskUrl})` , WebkitMaskPosition: `center` , WebkitMaskRepeat: `no-repeat` , WebkitMaskSize: `contain` , maskImage: `url(${maskUrl})` , maskPosition: `center` , maskRepeat: `no-repeat` , maskSize: `contain` }} />
            </Card>
          </Col>
        ))}
      </Row>
      {/* isOpen && (
        <AMCustomLightbox
          isOpen={isOpen}
          photoIndex={photoIndex}
          onMovePrev={() => setPhotoIndex((photoIndex + favoriteMashes.length - 1) % favoriteMashes.length)}
          onMoveNext={() => setPhotoIndex((photoIndex + 1) % favoriteMashes.length)}
          onClose={() => setIsOpen(false)}
          imageUrls={favoriteMashes.map(mash => mash.imageUrl)} // array of image URLs
          maskUrls={favoriteMashes.map(mash => mash.maskUrl)} // array of mask URLs
        />
      ) */}
    </>
  );
}

export default FavoriteMash;
