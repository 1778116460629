


import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'

export default function About() {
    return (
        <div>
            <div className="container">	
                <div className="row">
                    <div className="col mb-2 text-center">    
                        <h4 className="txt-crimson">Listed below are the names and values associated to supporters of ArtMash</h4>
                        <p className="txt-cultured px-2 mx-2">If you donate, I will reach out to you via the email in your PayPal account. I would be happy to include your information, and a link to support or reference you or your work if desired. If you would like to stay anonymous, that is fine as well. All donations are appreciated.</p>
                    </div>
                </div>
                <div className="row alight-items-center justify-content-center mb-5">
                    <div className="col-lg-3 mb-3"> 

                        <h3>Anonymous</h3>
                        <ul>
                            <li>Your name here!</li>
                        </ul>

                    </div>
                    <div className="col-lg-3 mb-3"> 

                        <h3>More than $100</h3>
                        <ul>
                            <li>Your name here!</li>
                        </ul>

                    </div>
                    <div className="col-lg-3 mb-3"> 

                        <h3>More than $50</h3>
                        <ul>
                            <li>Your name here!</li>
                        </ul>

                    </div>
                    <div className="col-lg-3 mb-3"> 

                        <h3>More than $10</h3>
                        <ul>
                            <li>Your name here!</li>
                        </ul>

                    </div>
                </div>        
            </div>
        </div>
    )
}
