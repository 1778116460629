import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';

function ImageGallery() {
  const [imageObjects, setImageObjects] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  // Set image limit
  const imageLimit = 3;

  useEffect(() => {
  const fetchData = async () => {
    const db = firebase.database();
    const snapshot = await db.ref('masks').get();
    const imageData = snapshot.val();

    const storage = firebase.storage();
    const imageObjects = await Promise.all(Object.keys(imageData).map(async (key) => {
      const url = await storage.ref(imageData[key].storageFilePath).getDownloadURL();
      return { ...imageData[key], url, id: key };
    }));

    // Sort images by uploadDateTime in descending order
    imageObjects.sort((a, b) => b.uploadDateTime - a.uploadDateTime);

    setImageObjects(imageObjects);
  };

  fetchData();
}, []);

  const handleDelete = async (image) => {
    const confirmation = window.confirm('Are you sure you want to delete this image?');
    if (confirmation) {
      const storage = firebase.storage();
      // Delete from storage
      await storage.ref(image.storageFilePath).delete();

      const db = firebase.database();
      // Delete from database
      await db.ref(`masks/${image.id}`).remove();

      alert('Image deleted successfully.');

      // Remove image from local state
      setImageObjects(imageObjects.filter(img => img.id !== image.id));
    }
  };

  return (
    <>
      {imageObjects.length > 0 ? (
      <>
        {imageObjects.slice(0, imageLimit).map((image, index) => (
          <Col className="px-1" xs={4} md={4} lg={4} key={index} onClick={() => {
            setIsOpen(true);
            setPhotoIndex(index);
          }}>
            <Card className="my-1 shadow-sm bg-cultured txt-vampire gallery-image-card">
              <Card.Img variant="top" src={image.url} className="polaroid" alt={image.amFilename} title={image.amFilename} />
            </Card>
          </Col>
        ))}

        {isOpen && (
          <Lightbox
            mainSrc={imageObjects[photoIndex].url}
            nextSrc={imageObjects[(photoIndex + 1) % imageObjects.length].url}
            prevSrc={imageObjects[(photoIndex + imageObjects.length - 1) % imageObjects.length].url}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + imageObjects.length - 1) % imageObjects.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imageObjects.length)
            }
          />
        )}
      </>
      ) : (
        <div className="text-center mt-5">
          <h4 className="txt-crimson">Nothing found. Please upload your content.</h4>
        </div>
      )}
    </>
  );
}

export default ImageGallery;
