import React, { useEffect, useRef, useState, PureComponent } from 'react';
import ReactGA from 'react-ga'
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom'
import 'firebase/storage';
import 'firebase/auth';
import { Button, Col, Container, Row, Card, Form } from 'react-bootstrap';
import './AMCamera.css';

// import {fetchProducts, orderProduct} from "../firebase"
import { useAuth } from "../contexts/FirebaseAuth"
import { useFlashUpdate } from "../contexts/FlashContext"

const CameraView = () => {
  const flash = useFlashUpdate()
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [frontCamera, setFrontCamera] = useState(true);
  const [sliderValue, setSliderValue] = useState(128);
  const [invertImage, setInvertImage] = useState(false);

  useEffect(() => {
    const constraints = {
      video: {
        facingMode: frontCamera ? 'user' : 'environment',
        width: { ideal: 1080 }, // or whatever resolution you want
        height: { ideal: 1080 },
      },
    };

    const initCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      } catch (error) {
        console.error(error);
      }
    };

    initCamera();

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [frontCamera]);

  const capturePhoto = async () => {

        const randomName = generateRandomName();
    
        if (canvasRef.current && videoRef.current) {
          const canvas = canvasRef.current;
          const video = videoRef.current;

          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          const ctx = canvas.getContext('2d');
          ctx.filter = invertImage ? 'invert(1)' : 'none';
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const threshold = sliderValue;

          for (let i = 0; i < imageData.data.length; i += 4) {
            const r = imageData.data[i];
            const g = imageData.data[i + 1];
            const b = imageData.data[i + 2];
            const gray = (r + g + b) / 3;

            if (gray > threshold) {
              imageData.data[i] = 0;
              imageData.data[i + 1] = 0;
              imageData.data[i + 2] = 0;
              imageData.data[i + 3] = 255; // Set alpha channel to fully opaque
            } else {
              imageData.data[i + 3] = 0; // Set alpha channel to fully transparent
            }
          }

          ctx.putImageData(imageData, 0, 0);

          const offscreenCanvas = document.createElement('canvas');
          const offscreenCtx = offscreenCanvas.getContext('2d');
          offscreenCanvas.width = canvas.width;
          offscreenCanvas.height = canvas.height;
          offscreenCtx.drawImage(canvas, 0, 0);

          const uid = firebase.auth().currentUser.uid;
          const filename = getFileName();
          const storageRef = firebase.storage().ref(`masks/${uid}/${filename}`);

          try {
            const fileBlob = await new Promise((resolve) =>
              offscreenCanvas.toBlob(resolve, 'image/png')
            );
            await storageRef.put(fileBlob);

            const downloadURL = await storageRef.getDownloadURL();

            const dbRef = firebase.database().ref('masks');
            await dbRef.push({
              filename,
              amFilename: randomName,
              uploaderUID: uid,
              storageFilePath: storageRef.fullPath,
              uploadDateTime: firebase.database.ServerValue.TIMESTAMP,
              score: 0.10, // initial score
            });

            // alert('Upload completed.');
            flash({message:"Camera Capture & Upload Successfull!", variant: "success"})
            // history.push("/profile#orders")
              
          } catch (error) {
            console.error(error);
            alert('Upload failed. Please try again.');
          }
        }
  };

  const switchCamera = () => {
    setFrontCamera((prevFrontCamera) => !prevFrontCamera);
  };

  const getFileName = () => {
    const now = new Date();
    const dateStr = `${now.getDate()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getFullYear()}`;
    const timeStr = `${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
    return `maskcam-${dateStr}${timeStr}-${randomString()}.png`;
  };

  const randomString = () => {
    return Math.random().toString(36).substring(2, 8);
  };

  const handleSliderChange = (e) => {
    setSliderValue(e.target.value);
  };

  // Generate a random 12-character string of hexadecimal digits
  const generateRandomName = () => {
  const characters = '0123456789abcdef';
  let result = '';
  for (let i = 0; i < 12; i++) {
    result += characters[Math.floor(Math.random() * characters.length)];
  }
  return result;
};

  return (
    <>
      <Row className="my-2 mx-0 text-center">
        <Col className="px-0">
            <h2>Masking Camera</h2>
            <p><b>Logged In Users:</b>Any photos taken will be saved to your Uploads. Captures are immediately made public.</p>
        </Col>
      </Row>
      
      <Row className="my-2 mx-0  text-center">
        <Col>
          <Button variant="light" className="mx-1" onClick={capturePhoto}>
            Take Photo
          </Button>
        </Col>
        <Col>
        <Button variant="light" className="mx-1" onClick={() => setInvertImage(!invertImage)}>
          Toggle Filter
        </Button>
        </Col>
        <Col>
          <Button variant="light" className="mx-1" onClick={switchCamera}>
            Switch Camera
          </Button>
        </Col>
      </Row>
      
      <Row className="hide-me">
        <Col>
          <div id="slider-container">
            <label htmlFor="slider">Threshold:</label>
            <input
              type="range"
              id="slider"
              name="slider"
              min="0"
              max="255"
              value={sliderValue}
              onChange={handleSliderChange}
            />
          </div>
        </Col>
      </Row>
      
      <Row className="my-2 mx-0  text-center mb-5 pb-5">
        <Col className="col-12-sm mx-auto px-0">
          {/* <video ref={videoRef} playsInline autoPlay muted /> */}
          <video ref={videoRef} playsInline autoPlay muted className={invertImage ? "video-inverted" : "video-normal"} />
          <div className="mb-1">Your captured image will appear below<br />View your Uploads to review and manage.</div>
          <canvas ref={canvasRef} />
        </Col>
      </Row>
      
    </>
  );
};

export default CameraView;
