import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/database';
// import AMTop3Images from './AMTop3Images';
// import AMTop3Masks from './AMTop3Masks';
// import AMGalleryImagesRec3 from './AMGalleryImagesRec3.js';
// import AMGalleryMasksRec3 from './AMGalleryMasksRec3.js';
import { useAuth } from "../contexts/FirebaseAuth";
import { useHistory } from 'react-router-dom';
import { useFlashUpdate } from "../contexts/FlashContext";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const RandomMash = () => {
  const [imageWebp, setImageWebp] = useState('');
  const [imageMask, setImageMask] = useState('');
  const [userFavoritesRef, setUserFavoritesRef] = useState(null);
  const [cumulativeScore, setCumulativeScore] = useState(0);
  // const [amUserName, setAmUserName] = useState("");
  const [imageScore, setImageScore] = useState(0);
  const [maskScore, setMaskScore] = useState(0);
  const [favoritesCount, setFavoritesCount] = useState(0);
  const [votesCount, setVotesCount] = useState(0);
  // const [currentUser, setCurrentUser] = useState('');

  useEffect(() => {
  const fetchRandomImages = async () => {
    const imagesUrl = await fetchRandomImageFromFolder('images');
    const masksUrl = await fetchRandomImageFromFolder('masks');

    setImageWebp(imagesUrl);
    setImageMask(masksUrl);
  };

  if (currentUser) {
    const userFavoritesRef = firebase.database().ref(`users/${currentUser.uid}/favorites`);
    setUserFavoritesRef(userFavoritesRef);

  }

  fetchRandomImages();
}, []); // <-- dependency on currentUser so that it runs when currentUser changes

useEffect(() => {
  const fetchUserScores = async () => {
    const imageRef = firebase.database().ref('images');
    const maskRef = firebase.database().ref('masks');

    const imageSnapshot = await imageRef.orderByChild('uploaderUID').equalTo(currentUser.uid).once('value');
    const maskSnapshot = await maskRef.orderByChild('uploaderUID').equalTo(currentUser.uid).once('value');

    let totalScore = 0;

    // Compute the score for the images
    imageSnapshot.forEach((childSnapshot) => {
      const image = childSnapshot.val();
      totalScore += image.score;
    });

    // Compute the score for the masks
    maskSnapshot.forEach((childSnapshot) => {
      const mask = childSnapshot.val();
      totalScore += mask.score;
    });

    setCumulativeScore(totalScore.toFixed(4));
  };

  if (currentUser) {
    fetchUserScores();
    calculateImageScore(currentUser.uid);
    calculateMaskScore(currentUser.uid);
  }
}, []);



 const fetchRandomImageFromFolder = async (folder, fetchAll = false) => {
  const dbRef = firebase.database().ref(folder);

  // Fetch only the last ## entries or all entries based on the fetchAll parameter
  const snapshot = fetchAll ? await dbRef.once('value') : await dbRef.limitToLast(25).once('value');
  const itemsData = snapshot.val();

  if (!itemsData) {
    // No items found
    return null;
  }

  // Convert itemsData from an object to an array, and shuffle it
  const itemsArray = Object.entries(itemsData);
  const shuffledItems = shuffleArray(itemsArray);

  // Pick the first item from the shuffled array
  const selectedEntry = shuffledItems[0];
  const [selectedKey, selectedItem] = selectedEntry;

  // Fetch item URL from Firebase Storage
  const fileRef = firebase.storage().ref(selectedItem.storageFilePath);
  const url = await fileRef.getDownloadURL();

  // Fetch the uploader's username
  const uploaderName = await fetchUserNameFromUID(selectedItem.uploaderUID);

  // Add the URL, the key, and the uploader's name to the selectedItem object
  selectedItem.url = url;
  selectedItem.dbKey = selectedKey;
  selectedItem.uploaderName = uploaderName;

  if (imageWebp && imageMask) {
  const mashId = `${imageWebp.dbKey}-${imageMask.dbKey}`;
  calculateFavoritesCount(mashId);
  calculateVotesCount(mashId);
  } else {}

  return selectedItem;
};


  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const handleClickImageWebp = () => {
      fetchRandomImageFromFolder('images', true).then(
        (url) => {
          setImageWebp(url || '');
        }
      );
    };

    const handleClickImageMask = () => {
        fetchRandomImageFromFolder('masks', true).then((url) => {
        setImageMask(url || '');
        });
    };

    // Function to handle upvote
const handleVoteUp = async () => {
    if(imageWebp && imageMask) {
      // Check if the user has already voted on this mash
      const mashId = `${imageWebp.amFilename}-${imageMask.amFilename}`;
      const voteRef = firebase.database().ref(`votes/${currentUser.uid}/${mashId}`);
      const snapshot = await voteRef.once('value');
      if (snapshot.exists()) {
        alert('You have already voted for this mash!');
        return;
      }

      const imageRef = firebase.database().ref(`images/${imageWebp.dbKey}`);
      const maskRef = firebase.database().ref(`masks/${imageMask.dbKey}`);

      // increase score by 0.1
      const newImageScore = imageWebp.score + 0.100000000000;
      const newMaskScore = imageMask.score + 0.100000000000;

      await imageRef.update({ score: newImageScore });
      await maskRef.update({ score: newMaskScore });
      
      // record the vote
      await voteRef.set('up');

      // Update local state
      setImageWebp({ ...imageWebp, score: newImageScore });
      setImageMask({ ...imageMask, score: newMaskScore });

      // alert on completion
      alert('You Mash Vote has been recorded.');
    }
};

// Function to handle downvote
const handleVoteDown = async () => {
    if(imageWebp && imageMask) {
      // Check if the user has already voted on this mash
      const mashId = `${imageWebp.amFilename}-${imageMask.amFilename}`;
      const voteRef = firebase.database().ref(`votes/${currentUser.uid}/${mashId}`);
      const snapshot = await voteRef.once('value');
      if (snapshot.exists()) {
        alert('You have already voted for this mash!');
        return;
      }

      const imageRef = firebase.database().ref(`images/${imageWebp.dbKey}`);
      const maskRef = firebase.database().ref(`masks/${imageMask.dbKey}`);

      // decrease score by 0.05
      const newImageScore = imageWebp.score - 0.050000000000;
      const newMaskScore = imageMask.score - 0.050000000000;

      await imageRef.update({ score: newImageScore });
      await maskRef.update({ score: newMaskScore });

      // record the vote
      await voteRef.set('down');

      // Update local state
      setImageWebp({ ...imageWebp, score: newImageScore });
      setImageMask({ ...imageMask, score: newMaskScore });

      // alert on completion
      alert('You Mash Vote has been recorded.');
    }
};


    const handleAddToFavorites = async () => {
  if (currentUser && imageWebp && imageMask) {
    // create a unique id for the mash
    const mashId = `${imageWebp.dbKey}-${imageMask.dbKey}`;

    // check if the mash is already favorited
    const snapshot = await userFavoritesRef.child(mashId).once('value');
    if (snapshot.exists()) {
      alert('You have already favorited this mash.');
    } else {
      // save the mash to the user's favorites
      await userFavoritesRef.child(mashId).set({
        image: imageWebp.dbKey,
        mask: imageMask.dbKey
      });

      alert('Mash added to favorites.');
      //flash({message:"Mash added to favorites!", variant: "success"};
    }
  }
};
    // fetch the amUserName of the given uploaderUID
    const fetchUserNameFromUID = async (uid) => {
      const userRef = firebase.database().ref(`users/${uid}`);
      const snapshot = await userRef.once('value');
      if (snapshot.exists()) {
        const userData = snapshot.val();
        return userData.amUserName;
      }
      return null;
    };

  const calculateImageScore = async (userId) => {
  const dbRef = firebase.database().ref('images');
  const snapshot = await dbRef.once('value');
  const itemsData = snapshot.val();
  let totalScore = 0;

  // Iterate over the data
  for (let key in itemsData) {
    if (itemsData[key].uploaderUID === userId) {
      totalScore += itemsData[key].score;
    }
  }

  setImageScore(totalScore.toFixed(4));
};

const calculateMaskScore = async (userId) => {
  const dbRef = firebase.database().ref('masks');
  const snapshot = await dbRef.once('value');
  const itemsData = snapshot.val();
  let totalScore = 0;

  // Iterate over the data
  for (let key in itemsData) {
    if (itemsData[key].uploaderUID === userId) {
      totalScore += itemsData[key].score;
    }
  }

  setMaskScore(totalScore.toFixed(4));
};

const calculateFavoritesCount = async (mashId) => {
  const dbRef = firebase.database().ref('users');
  const snapshot = await dbRef.once('value');
  const usersData = snapshot.val();
  let count = 0;

  // Iterate over the data
  for (let userId in usersData) {
    const favorites = usersData[userId].favorites;
    if (favorites && favorites[mashId]) {
      count++;
    }
  }

  setFavoritesCount(count);
};

const calculateVotesCount = async (mashId) => {
  const dbRef = firebase.database().ref(`votes/${mashId}`);
  const snapshot = await dbRef.once('value');
  
  setVotesCount(snapshot.numChildren());
};

function formatScore(score) {
  const formattedScore = parseFloat(score.toFixed(4));
  return formattedScore;
}


    
  const { currentUser } = useAuth()

  return (

    <>			  
      <div className="row ml-0 mr-0">
        <div className="col-12 col-sm-12 col-md-4 col-lg-3 my-auto order-last custom-card hide-me" id="left-nav">
          <div className="row pt-2 pb-0 text-bold hide-me">
            {/* Score Ticker */}
            <div className="col-12 text-center txt-cultured">
              <h3>Welcome back, <span className="txt-crimson"> {/* currentUser.displayName */}!</span></h3>
            </div>
          </div>
          <div className="row pt-0 pb-0 px-2">
            <div className="col-3 mt-2 mb-2 my-2 ar-square hide-me" id="profile-pic">
              <i className="fas fa-user-astronaut">&nbsp;</i>
            </div>
            <div className="col-12" id="profile-scores">
              <div className="row">
                <div className="col-4 mt-2 mb-2 my-2 text-center">
                    image total<br />
                  <i className="fas fa-images txt-cultured">&nbsp;&nbsp;&nbsp;{imageScore}</i>
                </div>
                <div className="col-4 mt-2 mb-2 my-2 text-center">
                    your total<br />
                  <i className="fas fa-star txt-crimson txt-spc1">&nbsp;&nbsp;&nbsp;{cumulativeScore}</i>
                </div>
                <div className="col-4 mt-2 mb-2 my-2 text-center">
                    mask total<br />
                  <i className="fas fa-theater-masks txt-cultured">&nbsp;&nbsp;&nbsp;{maskScore}</i>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-2 pb-2 py-2 mb-0 d-none d-lg-block">
            {/* Score Ticker */}
            <div className="col-12 text-center txt-silverfoil">
                {/* add any image or mask to the mash with a click */}
                check back often for more features!<br />&copy;RR v3.0.7.03.a
            </div>
          </div>
        </div>
      
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 my-auto mx-0 px-0" id="right-space">
          {/* RIGHT WORKSPACE */}
      
          <div className="row" id="worksp-top-msg text-center">
            <div className="col-12 text-center">
                <div className="img-tooltip-l">Tap to Change Image</div>
                <div className="img-tooltip-r">Tap to Change Mask</div>
            </div>
          </div>
      
          <div className="row hide-me" id="worksp-row-top">
            <div className="col-2 d-none d-lg-block d-xl-none" id="mash-bottom-blank">
              {/* BLANK SPACE */}
              &nbsp;
            </div>
            <div className="col-12 order-first" id="mash-bottom-menu">
          {/* Mask Preview */}
          <div className="row mx-0" id="mash-actions">
            <div className="col-2 pl-2" id>
              {/* Image Preview */}
              <button className="menu__button" onclick="switchProduct()" title="Re-Mash" id="shirt-button">
                <i className="fas fa-tshirt txt-cultured">&nbsp;</i>
              </button>
            </div>
            <div className="col-2 pl-2" id>
              {/* Image Preview */}
              <button className="menu__button" onclick="switchProduct()" title="Re-Mash" id="hoodie-button">
                <i className="fas fa-hood-cloak txt-cultured">&nbsp;</i>
              </button>
            </div>
            <div className="col-2 pl-2" id>
              {/* Image Preview */}
              <button className="menu__button" onclick="switchProduct()" title="Re-Mash" id="mug-button">
                <i className="fas fa-mug txt-cultured">&nbsp;</i>
              </button>
            </div>
            <div className="col-2 pl-2" id>
              {/* Image Preview */}
              <button className="menu__button" onclick="switchProduct()" title="Re-Mash" id="sticker-button">
                <i className="fas fa-sticky-note txt-cultured">&nbsp;</i>
              </button>
            </div>
            <div className="col-2 pl-2">
              {/* Image Preview */}
              <button className="menu__button" onclick="switchProduct()" title="Re-Mash" id="clear-button">
                <i className="fas fa-cancel txt-cultured">&nbsp;</i>
              </button>
            </div>
            <div className="col-2 pl-2">
              {/* Image Preview */}
              <button className="menu__button" onclick="showQuotes();" title="Quotes">
                <i className="fas fa-comments-alt txt-cultured">&nbsp;</i>
              </button>
            </div>
            <div className="col-2 hide-me">
              {/* Image Preview */}
              <button className="menu__button" onclick="javascript:location.reload();" title="Re-Mash">
                <i className="fas fa-save txt-cultured">&nbsp;</i>
              </button>
            </div>
            <div className="col-2 hide-me">
              {/* Image Preview */}
              <button className="menu__button" onclick="javascript:location.reload();" title="Re-Mash">
                <i className="fas fa-shopping-cart txt-cultured">&nbsp;</i>
              </button>
            </div>
          </div>
        </div>
            <div className="col-2 d-none d-lg-block d-xl-none" id="mash-bottom-blank">
              {/* BLANK SPACE */}
              &nbsp;
            </div>
          </div>
          
          <div className="row" id="worksp-row-mid">
            {/* Image & Mask Previews */}
            <div className="col-3 col-sm-3 col-md-4 col-lg-2 ar-square">
              {/* Image Preview */}
              <div className="preview-art" id="for-example">
                <a href="#" title="Change Image">
                      {imageWebp && (
                        <img
                          src={imageWebp.url}
                          alt={imageWebp.filename}
                          onClick={handleClickImageWebp}
                          className=""
                        />
                      )}
                </a>
              </div>
              <div className="col-12 artist-credit-image text-center hide-me">
                @ArtistNameHere
              </div>
            </div>
            <div className="col-2 col-sm-2 col-md-4 col-lg-2 text-center mt-1 hide-mobile pl-1">
              {/* Site Title & Version */}
              <div className="text-left">
                    Image Artist:<br /><b>{imageWebp.uploaderName}</b><br />
                    Image Added:<br /><b>{new Date(imageWebp.uploadDateTime).toLocaleDateString()}</b><br />
                    Image Score:<br /><b>{imageWebp.score}</b>
                    </div>
            </div>

            <div className="col flex text-center">
                
                <div className="row hide-mobile">
                    
                    {currentUser ? (
                    // JSX to render when the user is logged in
                    <div className="col my-auto order-last custom-card" id="left-nav">
                      <div className="row pt-0 pb-0 px-2">
                        <div className="col-3 mt-2 mb-2 my-2 ar-square hide-me" id="profile-pic">
                          <i className="fas fa-user-astronaut">&nbsp;</i>
                        </div>
                        <div className="col-12" id="profile-scores">
                          <div className="row">
                          
                            <div className="col-4 mt-2 mb-2 my-2 text-center">
                                image total<br />
                              <i className="fas fa-images txt-cultured">&nbsp;&nbsp;&nbsp;{imageScore}</i>
                            </div>
                            <div className="col-4 mt-2 mb-2 my-2 text-center">
                                your total<br />
                              <i className="fas fa-star txt-crimson txt-spc1">&nbsp;&nbsp;&nbsp;{cumulativeScore}</i>
                            </div>
                            <div className="col-4 mt-2 mb-2 my-2 text-center">
                                mask total<br />
                              <i className="fas fa-theater-masks txt-cultured">&nbsp;&nbsp;&nbsp;{maskScore}</i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-2 pb-2 py-2 mb-0 d-none d-lg-block">
                        <div className="col-12 text-center txt-silverfoil">
                            {/* add any image or mask to the mash with a click */}
                            check back often for more features! &copy;RR v3.0.7.06.b
                        </div>
                      </div>
                    </div>
                    ) : (
                    // JSX to render when no user is logged in
                    <div className="col-12 mt-6 mb-6 my-6 text-center hide-desktop">
                    &nbsp;
                    </div>
                    )}

                </div>

                <div className="row mt-4 hide-mobile">
                {currentUser ? (
                  // JSX to render when the user is logged in
                  <>
                  <div className="col flex text-center">
                        <button className="menu__button hide-mobile" onClick={handleVoteUp} title="Like Mash">
                            <i className="fas fa-thumbs-up">&nbsp;</i>
                        </button>
                    </div>
                    <div className="col text-center">
                        <button className="menu__button hide-mobile" onClick={handleAddToFavorites} title="Add to Favorites">
                            <i className="fa fa-heart">&nbsp;</i>
                        </button>
                    </div>
                    <div className="col text-center">
                        <button className="menu__button hide-mobile" onClick={handleVoteDown} title="Dislike Mash">
                            <i className="fas fa-thumbs-down">&nbsp;</i>
                        </button>
                    </div>
                    </>
                ) : (
                  // JSX to render when no user is logged in
                  <div className="col flex text-center">
                        <h3>Like what you see?<br /></h3>
                        <h4><b><a href="/login" className="txt-crimson bold">Log in</a></b> now to start voting &amp;<br />save your favorite mashes!</h4>
                        <a href="/login"><Button className="btn-danger px-1 py-1 " href="/login">Start Mashing!</Button></a>
                    </div>
                )}

                    


                </div>

                <div className="row pl-1 hide-desktop">
                    <div className="col flex text-left">
                        <b>{imageWebp.uploaderName}</b><br />
                        Score: {imageWebp.score}
                    </div>
                </div>
                <div className="row hide-desktop">
                    &nbsp;
                </div>
                <div className="row pr-1 hide-desktop">
                    <div className="col flex text-right">
                        <b>{imageMask.uploaderName}</b><br />
                        Score: {imageMask.score}
                    </div>
                </div>

            </div>


            {/* }<div className="col flex text-center">
                <button className="menu__button hide-mobile" onClick={handleVoteUp} title="Like Mash">
                    <i className="fas fa-thumbs-up">&nbsp;</i>
                </button>
            </div>
            <div className="col text-center">
                <button className="menu__button hide-mobile" onClick={handleAddToFavorites} title="Add to Favorites">
                    <i className="fa fa-heart">&nbsp;</i>
                </button>
            </div>
            <div className="col text-center">
                <button className="menu__button hide-mobile" onClick={handleVoteDown} title="Dislike Mash">
                    <i className="fas fa-thumbs-down">&nbsp;</i>
                </button>
            </div> */}
            <div className="col-2 col-sm-2 col-md-4 col-lg-2 text-center mt-1 hide-mobile pr-1">
              {/* Site Title & Version */}
              <div className="text-right">
                    Mask Artist:<br /><b>{imageWebp.uploaderName}</b><br />
                    Mask Added:<br /><b>{new Date(imageMask.uploadDateTime).toLocaleDateString()}</b><br />
                    Mask Score:<br /><b>{imageMask.score}</b>
                    </div>
            </div>
            <div className="col-3 col-sm-3 col-md-4 col-lg-2 ar-square">
              {/* Mask Preview */}
              <div className="preview-mask" id="for-example" onClick={handleClickImageMask}>
                <a href="#" title="Change Mask">
                  <div className="mask-image">
                  
                    {imageMask && (
                        <img
                          src={imageMask.url}
                          alt={imageMask.filename}
                          onClick={handleClickImageMask}
                          className="preview-image"
                        />
                      )}
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="row hide-me" id="worksp-row-mid mid-dup">
            {/* Image & Mask Previews */}
            <div className="col-5">
              {/* Image Preview */}
              <div className="text-left">
                    Image Artist:<br /><b>{imageWebp.uploaderName}</b><br />
                    Image Score:<br /><b>{imageWebp.score}</b>
                </div>
            </div>
            <div className="col flex">
              {/* Dead Space - is a great game */}
              &nbsp;
            </div>
             <div className="col-5 flex mr-3 pr-0">
              {/* Mask Preview */}
              <div className="text-right">
                    Mask Artist:<br /><b>{imageMask.uploaderName}</b><br />
                    Mask Score:<br /><b>{imageMask && imageMask.score && formatScore(imageWebp.score)}</b>
                    </div>
            </div>
          </div>

          {currentUser ? (
          // JSX to render when the user is logged in
          <>
          <div className="row ml-0 mr-0">
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 my-auto order-last custom-card hide-desktop" id="left-nav">
              <div className="row pt-0 pb-0 px-2">
                <div className="col-3 mt-2 mb-2 my-2 ar-square hide-me" id="profile-pic">
                  <i className="fas fa-user-astronaut">&nbsp;</i>
                </div>
                <div className="col-12" id="profile-scores">
                  <div className="row mx-0 px-0">
                    <div className="col-4 my-1 text-center">
                        <div className="scores-tip">your image total</div>
                      <i className="fas fa-images txt-cultured">&nbsp;&nbsp;&nbsp;{imageScore}</i>
                    </div>
                    <div className="col-4 my-1 text-center">
                        <div className="scores-tip">your total score</div>
                      <i className="fas fa-star txt-crimson txt-spc1">&nbsp;&nbsp;&nbsp;{cumulativeScore}</i>
                    </div>
                    <div className="col-4 my-1 text-center">
                        <div className="scores-tip">your mask total</div>
                      <i className="fas fa-theater-masks txt-cultured">&nbsp;&nbsp;&nbsp;{maskScore}</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
          ) : (
          // JSX to render when no user is logged in
            <div className="col flex text-center hide-mobile">
                &nbsp;
            </div>
          )}


          <div className="row mx-0" id="worksp-row-bot">
            {/* Image & Mask Previews */}
            <div className="col-2 mt-auto mb-auto my-auto hide-me" id="mash-backgrounds">
              {/* Background Changer */}
              <div className="row justify-content-center">
                {/* Backgroun Buttons (For Sharing) */}
                <div className="col-6 px-0" id="bg-buttons">
                  <div className="row">
                    <div className="col-12 ar-square">
                      <button className="menu__button" onclick="changeBackgroundImage();" title="Re-Mash">
                        <i className="fas fa-image">&nbsp;</i>
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ar-square">
                      <button className="menu__button" onclick="javascript:location.reload();" title="Re-Mash">
                        <i className="fas fa-video">&nbsp;</i>
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ar-square">
                      <button className="menu__button" onclick="javascript:location.reload();" title="Re-Mash">
                        <i className="fas fa-palette">&nbsp;</i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-6 d-none d-lg-block" id="left-mash-blank">
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="col-12 my-auto mx-0 px-5" id="Xmash-workspace">
              <div className="row" id="mash-regular">
                
                {imageWebp && (
                  <img
                    src={imageWebp.url}
                    alt="mash"
                    className="mash-image"
                    style={{ WebkitMaskImage: `url(${imageMask.url})` , WebkitMaskPosition: `center` , WebkitMaskRepeat: `no-repeat` , WebkitMaskSize: `contain` , maskImage: `url(${imageMask.url})` , maskPosition: `center` , maskRepeat: `no-repeat` , maskSize: `contain` }}
                  />
                )}

              </div>
            </div>
            {/* Image & Mask Previews */}

            <div className="col-2 mt-auto mb-auto my-auto hide-me" id="mash-voting">
              {/* Background Changer */}
              <div className="row justify-content-center">
                {/* Backgroun Buttons (For Sharing) */}
                <div className="col-6 d-none d-lg-block" id="right-mash-blank">
                  &nbsp;
                </div>
                <div className="col-6 px-0" id="vote-buttons">
                  <div className="row">
                    <div className="col-12 ar-square">
                      <button className="menu__button" onclick="javascript:location.reload();" title="Re-Mash">
                        <i className="fas fa-thumbs-up">&nbsp;</i>
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ar-square">
                      <button className="menu__button" onclick="javascript:location.reload();" title="Re-Mash">
                        <i className="fas fa-random">&nbsp;</i>
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 ar-square">
                      <button className="menu__button" onclick="javascript:location.reload();" title="Re-Mash">
                        <i className="fas fa-thumbs-down">&nbsp;</i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {currentUser ? (
          // JSX to render when the user is logged in 
          <div className="row mb-2 hide-desktop">
            <div className="col text-center">
                {/* votesCount */}
            </div>
            <div className="col-2 text-center">
                <button className="menu__button" onClick={handleVoteUp} title="Like Mash">
                    <i className="fas fa-thumbs-up">&nbsp;</i>
                </button>
            </div>
            <div className="col-2 text-center">
                <button className="menu__button" onClick={handleAddToFavorites} title="Add to Favorites">
                    <i className="fa fa-heart">&nbsp;</i>
                </button>
            </div>
            <div className="col-2 text-center">
                <button className="menu__button" onClick={handleVoteDown} title="Dislike Mash">
                    <i className="fas fa-thumbs-down">&nbsp;</i>
                </button>
            </div>
            <div className="col text-center">
                &nbsp;{/* favoritesCount */}
            </div>
          </div>
          ) : (
          // JSX to render when no user is logged in
          <div className="row mb-2 hide-desktop">
            <div className="col flex text-center">
                
                <a href="/login"><Button className="btn-danger px-1 py-1 " href="/login">Start Mashing!</Button></a>
                <h4><b><a href="/login" className="txt-crimson bold">Log in</a></b> now to start voting &amp;<br />save your favorite mashes!</h4>
            </div>
          </div>
          )}

          <div className="row hide-me">
            <div className="col-12 text-center">
                <button onClick={handleVoteUp}>Thumbs Up</button>
                Total Score: {(imageWebp ? imageWebp.score : 0) + (imageMask ? imageMask.score : 0)}
                <button onClick={handleVoteDown}>Thumbs Down</button>
                <button onClick={handleAddToFavorites}>Add to Favorites</button>
            </div>
          </div>

          <div className="row hide-me" id="worksp-top-msg text-center text-left">
                <div className="col-6">
                    <div className="img-tooltip-l">
                    Image Credit:<br />
                    {imageWebp && <p>Artist: {imageWebp.uploaderName}</p>}
                    {imageWebp && <p>Added: {new Date(imageWebp.uploadDateTime).toLocaleDateString()}</p>}
                    {imageWebp && <p>Score: {imageWebp.score}</p>}
                    {imageWebp && <p>Extra: {imageWebp.dbKey}</p>}

                    <div>
                    Image Artist:<br /><b>{imageWebp.uploaderName}</b><br />
                    Image Added:<br /><b>{new Date(imageWebp.uploadDateTime).toLocaleDateString()}</b><br />
                    Image Score:<br /><b>{imageWebp.score}</b>
                    </div>

                    </div>
                    
                </div>
                <div className="col-6">
                    
                    <div className="img-tooltip-r text-right">
                    Mask Credit:<br />
                    {imageMask && <p>Artist: {imageMask.uploaderName}</p>}
                    {imageMask && <p>Added: {new Date(imageMask.uploadDateTime).toLocaleDateString()}</p>}
                    {imageMask && <p>Score: {imageMask.score}</p>}
                    {imageWebp && <p>Extra: {imageMask.dbKey}</p>}
                    
                    <div>
                    Mask Artist:<br /><b>{imageWebp.uploaderName}</b><br />
                    Mask Added:<br /><b>{new Date(imageMask.uploadDateTime).toLocaleDateString()}</b><br />
                    Mask Score:<br /><b>{imageMask.score}</b>
                    </div>

                    </div>
                </div>
              </div>
          </div>

      </div>
    </>
  );
};

export default RandomMash;