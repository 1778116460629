import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/database';
import { useHistory } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FaTrashAlt } from 'react-icons/fa';
import './AMUploads.css';
import { useFlashUpdate } from "../contexts/FlashContext";
import AMGalleryImagesUID from './AMGalleryImagesUID.js';
import AMGalleryMasksUID from './AMGalleryMasksUID.js';


const ImageGallery = () => {
  const [imagesWebp, setImagesWebp] = useState([]);
  const [imagesMasks, setImagesMasks] = useState([]);
  const [photoIndexWebp, setPhotoIndexWebp] = useState(0);
  const [photoIndexMasks, setPhotoIndexMasks] = useState(0);
  const [isOpenWebp, setIsOpenWebp] = useState(false);
  const [isOpenMasks, setIsOpenMasks] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = firebase.storage().ref();
      const uid = firebase.auth().currentUser.uid;
      
      const imagesWebpRef = storageRef.child(`images-webp/Uploads/${uid}`);
      const masksRef = storageRef.child(`masks/${uid}`);
      
      const [imagesWebpFiles, masksFiles] = await Promise.all([
        imagesWebpRef.listAll(),
        masksRef.listAll()
      ]);

      // Fetch from Firebase Realtime Database to get dbKey
      const dbRef = firebase.database().ref('images');
      const snapshot = await dbRef.once('value');
      const data = snapshot.val();

      const imagesWebpUrls = await Promise.all(
        imagesWebpFiles.items.map(async (file) => {
          const { fullPath } = file;
          const dbKey = data[fullPath]?.dbKey;

          return {
            url: await file.getDownloadURL(),
            path: fullPath,
            dbKey
          };
        })
      );
      
      const masksUrls = await Promise.all(
        masksFiles.items.map(async (file) => {
          const { fullPath } = file;
          const dbKey = data[fullPath]?.dbKey;

          return {
            url: await file.getDownloadURL(),
            path: fullPath,
            dbKey
          };
        })
      );
      
      setImagesWebp(imagesWebpUrls);
      setImagesMasks(masksUrls);
    };

    fetchImages();
  }, []);

  const handleDelete = async (image) => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(image.path);

      await fileRef.delete();

      const dbRef = firebase.database().ref('images').child(image.dbKey);
      await dbRef.remove();

      setImagesWebp(imagesWebp.filter((img) => img.path !== image.path));
      setImagesMasks(imagesMasks.filter((img) => img.path !== image.path));
      alert('This image has been deleted.');
      //flash({message:"File Deleted!", variant: "success"})
      //history.push("/profile#orders")
    }
  };

  return (
    <>
      <Row className="my-2 text-center">
        <Col>
            <h2>Personal Gallery</h2>
            <p>While logged in, all images and masks are viewable on this page. Select an image to view a larger version. Select the trash can to remove an image from the platform.</p>
        </Col>
      </Row>
      <h2>Your Images</h2>
      <Row className="px-2">
        <AMGalleryImagesUID />
      </Row>
        <br />
      <h2>Your Masks</h2>
      <Row className="px-2">
        <AMGalleryMasksUID />
      </Row>

      {isOpenWebp && (
        <Lightbox
          mainSrc={imagesWebp[photoIndexWebp].url}
          nextSrc={imagesWebp[(photoIndexWebp + 1) % imagesWebp.length].url}
          prevSrc={imagesWebp[(photoIndexWebp + imagesWebp.length - 1) % imagesWebp.length].url}
          onCloseRequest={() => setIsOpenWebp(false)}
          onMovePrevRequest={() =>
            setPhotoIndexWebp((photoIndexWebp + imagesWebp.length - 1) % imagesWebp.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndexWebp((photoIndexWebp + 1) % imagesWebp.length)
          }
          imageCaption={"Click or tap to close"}
        />
      )}

      {isOpenMasks && (
        <Lightbox
          mainSrc={imagesMasks[photoIndexMasks].url}
          nextSrc={imagesMasks[(photoIndexMasks + 1) % imagesMasks.length].url}
          prevSrc={imagesMasks[(photoIndexMasks + imagesMasks.length - 1) % imagesMasks.length].url}
          onCloseRequest={() => setIsOpenMasks(false)}
          onMovePrevRequest={() =>
            setPhotoIndexMasks((photoIndexMasks + imagesMasks.length - 1) % imagesMasks.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndexMasks((photoIndexMasks + 1) % imagesMasks.length)
          }
          imageCaption={"Click or tap to close"}
        />
      )}
    </>
  );
};

export default ImageGallery;
