import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageUpload from './AMImageUpload2';
import MaskImageUpload from './AMMaskUpload2';
import AMUploads from './AMUploads';
import AMTop3Images from './AMTop3Images';
import AMTop3Masks from './AMTop3Masks';
import AMGalleryImagesRec3 from './AMGalleryImagesRec3.js';
import AMGalleryMasksRec3 from './AMGalleryMasksRec3.js';
//mult import test
import AMMultImageUpload from './AMMultImageUpload.js';
import AMMultMaskUpload from './AMMultMaskUpload.js';
import AMJustImages from './AMJustImages.js';
import AMJustMasks from './AMJustMasks.js';
//import AMGalleryImagesRec10 from './AMGalleryImagesRec10.js';
//import AMGalleryMasksRec10 from './AMGalleryMasksRec10.js';

const UploadPage = () => {
  return (
  <>
      <Row className="my-2 text-center">
        <Col>
            <h2>Share Your Art!</h2>
            <p><b>Logged In Users:</b> Any files added to Images or Masks will be saved to Uploads. All uploads are public.</p>
        </Col>
      </Row>
      
      <Row className="mx-0">
        <div className="col-sm-12 col-md-6 px-2 mb-3">
            <ImageUpload />
        </div>
        <div className="col-sm-12 col-md-6 px-2 mb-3">
            <MaskImageUpload />
        </div>
      </Row>
      
      <Row className="mx-0 mb-5 pb-5">
        <div className="col-sm-12 col-md-6 px-0 mb-3">
            <h4>Examples of Images</h4>  
            <AMTop3Images />
        </div>
        <div className="col-sm-12 col-md-6 px-0">
            <h4>Examples of Masks</h4>  
            <AMTop3Masks />
        </div>
      </Row>
      
      {/* 
      <Row className="mx-0">
      <div className="col-md-6 mb-3 order-1">
      <Row className="mb-3">
          <ImageUpload />
      </Row>
      <Row className="mx-0">
        <h4>Review Your Images Here</h4>  
        <AMTop3Images />
      </Row>
      </div>
      
      <div className="col-md-6 mb-5 order-2">
      <Row className="mb-3">
          <MaskImageUpload />
      </Row>
      <Row className="mx-0 mb-5">
        <h4>Review Your Masks Here</h4>  
        <AMTop3Masks />
      </Row>
      </div>
      </Row>
      */}
  </>
  );
};

export default UploadPage;
