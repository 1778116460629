// import logo from './logo.svg';
// import Button from 'react-bootstrap/Button';
// import Card from 'react-bootstrap/Card';
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import AMRandomMash from './AMRandomMash';



function App() {
  return (
        
      
      <div>     
        <div className="row text-center align-items-center justify-content-center my-auto py-auto amHome2-1st">
            <div className="col-sm-7" style={{borderRadius: '13px', backgroundColor: 'rgb(0,0,0,0.5)' }}>
                <h2>What can you do with<br /><img src="./assets/artmash_logo_color.png" style={{maxWidth: '75%'}} alt="ArtMash Logo" title="ArtMash | Pairing Art to Create New Designs" /> <span style={{color: '#CC2727', display: 'none'}}>ArtMash</span>?</h2><br />
                  <h4>The goal of 'ArtMash' is to facilitate random collaborations between artists.</h4>				  
                  <h5 style={{lineHeight: '1.75em'}} className="mb-3">
                    Share your artwork<br />
                    Combine it with others<br />
                    Find new interests<br />
                    more coming soon...<br />
                  </h5>
                  <a href="/explore"><button className="btn btn-danger cta-button px-2 py-1 mb-1" title="Start Mashing" href="/explore" style={{fontSize: '2em'}}>start mashing now</button></a>
                  <p>...or keep reading below</p>
            </div>
            <div className="col-sm-5 my-2 hide-mobile">
                {/* everchanging gif<br />of random mashes */}
                <AMRandomMash />
            </div>
        </div>

        <div className="row text-center align-items-center justify-content-center mb-5 amHome2-2nd">
            <div className="col-sm-5 my-3">
                <img src="./assets/amWorkPrev.webp" alt="artmash explore page example" style={{width: '100%', border: '3px solid #E6E6E6', borderRadius: '13px'}} />
            </div>
            <div className="col-sm-7" style={{borderRadius: '13px', backgroundColor: 'rgb(0,0,0,0.5)' }}>
                <h2>Why did we create<br /><img src="./assets/artmash_logo_color.png" style={{maxWidth: '75%'}} alt="ArtMash Logo" title="ArtMash | Pairing Art to Create New Designs" className="mb-3"/><span style={{color: '#CC2727', display: 'none'}}>ArtMash</span>?</h2>
                  <h4>ArtMash was created with the idea to replace doom-scrolling social media apps with a new interactive way to share, create, and purchase unique creations.</h4>
                  <br />
                  <h4>Select either image in the top corners to "re-roll" on that art or mask. Save your favorite mashes for more options later!</h4>
                  <a href="/explore"><button className="btn btn-danger cta-button px-2 py-2 mb-1 mt-2" title="Start Mashing" href="/explore" style={{fontSize: '1.5em'}}>create your first mash</button></a>
                  <p>...or keep reading below</p>
            </div>
        </div>

        <div className="row text-center align-items-center justify-content-center mb-5 amHome2-3rd">
            <div className="col-sm-4 flex" style={{borderRadius: '13px', backgroundColor: 'rgb(0,0,0,0.5)' }}>
                <h2>'Explore' Community Uploads</h2>
                <a href="/explore" style={{textDecoration: 'none', color: '#CC2727'}} title="Explore"><i class="fas fa-compass my-3" style={{fontSize: '4rem'}}></i></a>
                <h4>Anyone can "re-mash" art and masks submitted by other "artmashers". New content is being added daily. Submit your own on the 'Share' page.</h4>
                  <a href="/explore"><button className="btn btn-danger cta-button px-2 py-1 mb-3 mt-2" title="Start Exploring Mashes" href="/explore">start exploring</button></a>
            </div>
            <div className="col flex my-3">{/* camera view<br />placeholder */}<img src="./assets/maskCamPrev.webp" style={{maxWidth: '90%'}} alt="ArtMash Creations"/></div>
            <div className="col-sm-4 flex" style={{borderRadius: '13px', backgroundColor: 'rgb(0,0,0,0.5)' }}>
                <h2>Create Masks with 'Camera'</h2>
                <a href="/camera" style={{textDecoration: 'none', color: '#CC2727'}} title="Explore"><i class="fas fa-compass my-3" style={{fontSize: '4rem'}}></i></a>
                <h4>Use our depth-masked camera to create binary images for use with masking other full color art &amp; images. A new way to view the world!</h4>
                  <a href="/camera"><button className="btn btn-danger cta-button px-2 py-1 mb-3 mt-2" title="Start Capturing Masks" href="/camera">start capturing</button></a>
            </div>
        </div>

        <div className="row text-center align-items-center justify-content-center mb-5 amHome2-4th pb-5">
            <div className="col-sm-3 flex" style={{borderRadius: '13px', backgroundColor: 'rgb(0,0,0,0.5)' }}>
                <h2>Add New Images</h2>
                <i class="fas fa-file-upload py-2" style={{fontSize: '4rem', color: '#CC2727'}}></i>
                <p style={{lineHeight: '1.75rem', fontSize: '1.225em'}}>
                  Share a killer scenic photo<br />
                  Post a unique color drawing<br />
                  Upload something to share<br />
                  View the world elsewhere<br />
                </p>
                <a href="/share"><button className="btn btn-danger cta-button px-2 py-1 mb-3 mt-2" title="Upload Images" href="/share">upload images</button></a>
            </div>
            {/* <div className="col flex">random images</div> */}
            <div className="col flex my-3"><img src="./assets/am-home-bg01.webp" style={{maxWidth: '90%'}} alt="ArtMash Creations"/></div>
            <div className="col-sm-3 flex" style={{borderRadius: '13px', backgroundColor: 'rgb(0,0,0,0.5)' }}>
                <h2>Add New Masks</h2>
                <i class="fas fa-file-upload py-2" style={{fontSize: '4rem', color: '#CC2727'}}></i>
                <p style={{lineHeight: '1.75rem', fontSize: '1.225em'}}>
                  Capture a weird pattern<br />
                  Snap your line art drawing<br />
                  Photograph surroundings<br />
                  Share your graphic arts<br />
                </p>
                <a href="/share"><button className="btn btn-danger cta-button px-2 py-1 mb-3 mt-2" title="Upload Masks" href="/share">upload masks</button></a>
            </div>
        </div>

        <div className="row text-center align-items-center justify-content-center my-2 pb-5">
            <div className="col flex">
            <h4>Thanks for visiting! We hope to see you again soon!</h4>
                <img src="./assets/artmash_logo_color2.png" style={{maxWidth: '100%'}} alt="ArtMash Logo" title="ArtMash | Pairing Art to Create New Designs" />
            </div>
        </div>
      
        
        
        
      {/* }
      <img src="./assets/artmash_logo_color2.png" style={{maxWidth: '75%'}} alt="ArtMash Logo" title="ArtMash | Pairing Art to Create New Designs" />  
      <a href="/share"><Button className="btn-danger py-3 px-" href="/explore">Start Mashing!</Button></a>
      */}
      </div>
  );
}

export default App;
