import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageUpload from './AMImageUpload2';
import MaskImageUpload from './AMMaskUpload';
import AMUploads from './AMUploads';
//mult import test
import AMMultImageUpload from './AMMultImageUpload.js';
import AMMultMaskUpload from './AMMultMaskUpload.js';
import AMJustImages2 from './AMJustImages2.js';
import AMGalleryImages from './AMGalleryImages.js';
import AMGalleryMasks from './AMGalleryMasks.js';
import AMGalleryImagesUID from './AMGalleryImagesUID.js';
import AMGalleryMasksUID from './AMGalleryMasksUID.js';
import AMGalleryImagesRec10 from './AMGalleryImagesRec10.js';
import AMGalleryMasksRec10 from './AMGalleryMasksRec10.js';
import AMGalleryImagesPaged from './AMGalleryImagesPaged.js';
import AMGalleryMasksPaged from './AMGalleryMasksPaged.js';


const GodModeActions = () => {
  return (
  <>
      <Row className="my-2 text-center">
        <Col>
            <h2>IDDQD - ArtMash GodMode</h2>
            <p><b>Logged In Users:</b> Any files added to Images or Masks will be saved to Uploads. All uploads are public.</p>
        </Col>
      </Row>

      <Row className="mx-0">
        <div className="col-sm-12 col-md-6 px-2 mb-3">
            <AMMultImageUpload />
        </div>
        <div className="col-sm-12 col-md-6 px-2 mb-3">
            <AMMultMaskUpload />
        </div>
      </Row>

      {/* <Row className="mx-0">
        <div className="col-sm-12 col-md-6 px-2 mb-3">
            <AMGalleryImages />
        </div>
        <div className="col-sm-12 col-md-6 px-2 mb-3">
            <AMGalleryMasks />
        </div>
      </Row> */}

      <Row className="mx-0">
            <h3>All Image Uploads</h3>
            <AMGalleryImagesPaged />
      </Row>

      <Row className="mx-0">
            <h3>All Mask Uploads</h3>
            <AMGalleryMasksPaged />
      </Row>

      {/*
      <Row className="mx-0">
            <h3>User Image Uploads</h3>
            <AMGalleryImagesUID />
      </Row>

      <Row className="mx-0">
            <h3>User Mask Uploads</h3>
            <AMGalleryMasksUID />
      </Row>

      <Row className="mx-0">
            <h3>Last 10 Images Uploaded</h3>
            <AMGalleryImagesRec10 />
      </Row>
      
      <Row className="mx-0">
      <div className="col-md-6 mb-3 order-1">
      <Row className="mb-3">
          <ImageUpload />
      </Row>
      <Row className="mx-0">
        <h4>Review Your Images Here</h4>  
        <AMTop3Images />
      </Row>
      </div>
      
      <div className="col-md-6 mb-5 order-2">
      <Row className="mb-3">
          <MaskImageUpload />
      </Row>
      <Row className="mx-0 mb-5">
        <h4>Review Your Masks Here</h4>  
        <AMTop3Masks />
      </Row>
      </div>
      </Row>
      */}
  </>
  );
};

export default GodModeActions;
