import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from 'react-bootstrap';

// Initialize Firebase if not already initialized
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'your_api_key',
    authDomain: 'your_auth_domain',
    databaseURL: 'your_database_url',
    projectId: 'your_project_id',
    storageBucket: 'your_storage_bucket',
    messagingSenderId: 'your_messaging_sender_id',
    appId: 'your_app_id'
  });
}

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const fetchRandomImageFromFolder = async (folder, fetchAll = false) => {
  const dbRef = firebase.database().ref(folder);

  // Fetch only the last ## entries or all entries based on the fetchAll parameter
  const snapshot = fetchAll ? await dbRef.once('value') : await dbRef.limitToLast(25).once('value');
  const itemsData = snapshot.val();

  if (!itemsData) {
    // No items found
    return null;
  }

  // Convert itemsData from an object to an array, and shuffle it
  const itemsArray = Object.entries(itemsData);
  const shuffledItems = shuffleArray(itemsArray);

  // Pick the first item from the shuffled array
  const selectedEntry = shuffledItems[0];
  const [selectedKey, selectedItem] = selectedEntry;

  // Fetch item URL from Firebase Storage
  const fileRef = firebase.storage().ref(selectedItem.storageFilePath);
  const url = await fileRef.getDownloadURL();

  // Add the URL and the key to the selectedItem object
  selectedItem.url = url;
  selectedItem.dbKey = selectedKey;

  return selectedItem;
};

function RandomMash() {
  const [currentMash, setCurrentMash] = useState(null);

  useEffect(() => {
    // Set an interval to fetch a new random mash every 7 seconds
    const intervalId = setInterval(async () => {
      const randomImage = await fetchRandomImageFromFolder('images');
      const randomMask = await fetchRandomImageFromFolder('masks');

      if (randomImage && randomMask) {
        setCurrentMash({ imageUrl: randomImage.url, maskUrl: randomMask.url });
      }
    }, 6500);

    return () => clearInterval(intervalId);
  }, []);

  return currentMash ? (
    <Card className="py-auto" style={{borderRadius: '13px', backgroundColor: 'transparent', border: 'none'}}>
      <Card.Img
        variant="top"
        src={currentMash.imageUrl}
        style={{
          WebkitMaskImage: `url(${currentMash.maskUrl})`,
          WebkitMaskPosition: 'center',
          WebkitMaskRepeat: 'no-repeat',
          WebkitMaskSize: 'contain',
          maskImage: `url(${currentMash.maskUrl})`,
          maskPosition: 'center',
          maskRepeat: 'no-repeat',
          maskSize: 'contain',
        }}
      />
    </Card>
  ) : (
    <div>{/* Mashes Loading... */}
    <img src="./assets/loading.gif" style={{maxWidth: '94%'}} />
    </div>
  );
}

export default RandomMash;
