

import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/database';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import React from 'react'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { useFlashUpdate } from "../contexts/FlashContext"

const ImageUpload = () => {
  const flash = useFlashUpdate()
  const [file, setFile] = useState(null);

  // Handle file selection
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      if (
        ['image/jpeg', 'image/jpg', 'image/webp'].includes(e.target.files[0].type) &&
        e.target.files[0].size <= 10000000 // 10MB
      ) {
        setFile(e.target.files[0]);
      } else {
        alert('Invalid file type or size too large.');
      }
    }
  };

  // Handle upload
  const handleUpload = async () => {
  if (file) {
    const storageRef = firebase.storage().ref();

    // Generate a random name for the file
    const randomName = generateRandomName();
    // Extract the file extension from the original file name
    const fileExtension = file.name.split('.').pop();
    const newFileName = `${randomName}.${fileExtension}`;

    const fileRef = storageRef.child(`images-webp/Uploads/${firebase.auth().currentUser.uid}/${newFileName}`);

    const dbRef = firebase.database().ref('images');
    const newImageRef = dbRef.push();
    await newImageRef.set({
      filename: file.name,
      amFilename: randomName,
      uploaderUID: firebase.auth().currentUser.uid,
      storageFilePath: fileRef.fullPath,
      uploadDateTime: firebase.database.ServerValue.TIMESTAMP,
      score: 0.00 // initial score
    });

    const metadata = {
      customMetadata: {
        'dbKey': newImageRef.key
      }
    };

    await fileRef.put(file, metadata);

    // alert('Upload completed.');
    flash({message:"Image Upload Successfull!", variant: "success"})
  } else {
    flash({message:"No Image Selected!", variant: "danger"})
  }
};

// Generate a random 12-character string of hexadecimal digits
const generateRandomName = () => {
  const characters = '0123456789abcdef';
  let result = '';
  for (let i = 0; i < 12; i++) {
    result += characters[Math.floor(Math.random() * characters.length)];
  }
  return result;
};

  return (
    <div className="custom-card px-2 py-2">
      <Form>
        <Form.Group>
          <Form.Label><h4>Add Your New Images Here</h4></Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
        </Form.Group>
        <p>Upload your own photography, graphic arts, or any edge-to-edge composition. Files uploaded as images are seen through the non-transparent part of masks.</p>
        <Button variant="danger" onClick={handleUpload}>
          Upload
        </Button>
      </Form>
    </div>
  );
};



export default ImageUpload;
